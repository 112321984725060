import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { exportUser, getUsers, displaySuccess, displayError, getReportedUsers, reportAction } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import config from '../config/config';
import axios from 'axios';

const ReportedUser = (props) => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState('');
  const [sort, setSort] = useState('desc');

  const getList = async (search) => {
    if (search == '') {
      props.toggleLoader(true);
    }
    var getUsers_var = await getReportedUsers(limit, skip, search, sort);
    var page = getUsers_var.data.total / 10
    setTotal(getUsers_var.data.total)
    setPageCount(Math.ceil(page))
    setUsers(getUsers_var.data?.data);
    props.toggleLoader(false);

    // props.toggleLoader(true);
    // var getUsers_var = await getUsers(limit, skip);
    // setUsers(getUsers_var.data);
    // props.toggleLoader(false);
  }

  const deleteUser = (id) => {
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/delete-user/' + id;
    var token = localStorage.getItem('token');
    axios.get(path, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
    )
      .then(function (response) {
        props.toggleLoader(false);
        getList(search)
        displaySuccess(response?.data?.mesaage);

        // setTimeout(() => {
        //   window.location.reload()
        // }, 1500);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }

  useEffect(() => {
    getList(search)
  }, [skip, search, sort])

  const exportList = async () => {
    var res = await exportUser()
    if (res.status == 200) {
      window.location.href = res.data
    }
  }


  const handlePageClick = async (e) => {
    props.toggleLoader(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected;
    setSkip(selectedPage * limit)
    // getList(search)

    props.toggleLoader(false);
  };

  const actionReport = async (id) => {
    var res = await reportAction(id)
    if (res.status == 200) {
      getList(search)

    }
  }
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Reported Users</a></li>
                        </ol>
                      </nav>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-6">
                          <h3 className="mb-0">Reported Users Listing ({total})</h3>
                        </div>
                        <div className='col-6 display-end' >
                          <input type="text" className="form-control"
                            style={{ width: '35%', marginRight: 10 }}
                            placeholder="Search"
                            value={search}
                            onChange={(e) => {
                              setSkip(0)
                              setPageCount(1)
                              setSearch(e.target.value)
                              // const value = e.target.value;
                              // const regex = /^[0-9]+$/;
                              // if (value.match(regex) || value === "") {
                              //   setQuantity(value)
                              // }
                            }}
                          />
                          {/* <button className="btn btn-primary " onClick={() => exportList()}>Export</button> */}
                        </div>
                      </div>
                      <div className='row'>
                        <div className="form-group" style={{ width: '15%' }}>
                          <label className="form-control-label" >Sort</label>
                          <select className="form-control"
                            onChange={e => setSort(e.target.value)}
                            value={sort}
                          >
                            <option value="desc">Date descending</option>
                            <option value="asc">Date ascending</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>Name</th>
                            <th>Reported By</th>
                            <th>Email</th>
                            {/* <th>Role</th> */}
                            <th>Report Reason</th>
                            <th>Report Date</th>
                            <th style={{ textAlign: 'center' }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.length > 0 ? users?.map((user) => (
                            <tr>
                              <td className="table-user">
                                <Link to={"/user-detail/" + user.reported_user} target="_blank">
                                  <b className='name'>{user.user?.first_name}  {user.user?.last_name}</b>
                                </Link>
                              </td>
                              <td className="table-user">
                                <b className='name2'>{user.doctor?.first_name} {user?.doctor?.last_name} </b>
                              </td>
                              <td className="table-user">
                                <b className='name2'>{user?.user?.email} </b>
                              </td>
                              {/* <td className="table-user">
                                <b className='name2'>Physio</b>
                              </td> */}
                              <td className="table-user">
                                <span className="text-muted address">{user.report_reason} </span>
                              </td>

                              <td>
                                {user?.createdAt !== undefined ?
                                  <span className="text-muted date">{moment(user.createdAt).format('ddd, DD MMM YYYY HH:MM')} </span>
                                  :
                                  <span className="text-muted date">{moment(user.created_at).format('ddd, DD MMM YYYY HH:MM')} </span>}
                              </td>
                              <td className="td-wrapper">
                                {user?.action_taken == true ?
                                  <b className='name2'>Action Taken</b>
                                  :
                                  <button className="btn btn-primary pull-right" style={{ width: '140px' }}
                                    onClick={() => actionReport(user?._id)}>Take Action</button>
                                }
                              </td>
                            </tr>
                          ))
                            :
                            <td colSpan={7}>

                              <div style={{ textAlign: 'center' }}>
                                No Reported User
                              </div>
                            </td>


                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {users.length > 0 &&
                <Row>
                  {/* <Col xs={4}>
                    <div className='mb-1'>
                      <FormattedMessage id="Showing" /> {users?.length} <FormattedMessage id="accountants from page" /> {current_page} <FormattedMessage id="of" /> {pageCount}
                    </div>
                  </Col> */}
                  <Col xs={12}>
                    <div className='paginate-css'>
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        // subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </Col>
                </Row>}

              {/* {users && users.length > 0 &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {skip != 0 &&
                    <button className="btn btn-primary mr-4" onClick={(e) => {
                      setSkip(skip - limit)
                    }}> Prev </button>
                  }
                  {users.length != 0 && users?.length == limit &&
                    <button className="btn btn-primary pull-right" onClick={(e) => {
                      setSkip(skip + limit)
                    }} > Next </button>
                  }
                </div>
              } */}
            </div>

          </main>
        </div >
      </body >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportedUser);
