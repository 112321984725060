import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { exportUser, getEscrowDetail, getEscrowDetailForecast, getEscrowList, getUsers } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

const EscrowTransactionForecast = (props) => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState('');

  const getList = async (search) => {
    props.toggleLoader(true);
    var getUsers_var = await getEscrowDetailForecast(props.match.params.id);
    console.log(getUsers_var?.data)
    setUsers(getUsers_var?.data)
    props.toggleLoader(false);

    // props.toggleLoader(true);
    // var getUsers_var = await getUsers(limit, skip);
    // setUsers(getUsers_var.data);
    // props.toggleLoader(false);
  }

  useEffect(() => {
    getList()
  }, [])

  const exportList = async () => {
    var res = await exportUser()
    if (res.status == 200) {
      window.location.href = res.data
    }
  }


  const handlePageClick = async (e) => {
    props.toggleLoader(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected;
    setSkip(selectedPage * limit)
    // getList(search)

    props.toggleLoader(false);
  };
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/escrow">Escrow</Link ></li>
                            <li className="breadcrumb-item active" aria-current="page">View Transactions</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">View Transactions</h3>
                      </div>
                      <div className="card-body">
                        {/* <div className="row"> */}
                        <div className="col-md-4 pl-0">
                          {/* <div className="doc_type">Photo Id</div> */}
                          <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Name : {users?.physio?.full_name}</label>
                          <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Email : {users?.physio?.email}</label>
                          <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Phone number : {users?.physio?.phone_number}</label>
                          <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Address : {users?.physio?.address}</label>

                        </div>
                        <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Sessions</label>
                        <div>
                          <div className="table-responsive">
                            <table className="table align-items-center table-flush">
                              <thead className="thead-light">
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Phone Number</th>
                                  <th>Amount</th>
                                  <th>Payment Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {users?.sessions?.map((user) => (
                                  <tr>
                                    <td className="table-user">
                                      <Link to={"/user-detail/" + user._id} target="_blank">
                                        <b className='nameee'>{user.user?.first_name}  {user.user?.last_name}</b>
                                      </Link>
                                    </td>
                                    <td className="table-user">
                                      <b className='name2'>{user.user?.email} </b>
                                    </td>

                                    <td>
                                      <span className="text-muted date">{user.user?.phone_number} </span>
                                    </td>
                                    <td>
                                      <span className="text-muted date">{user.amount} </span>
                                    </td>
                                    <td>
                                      <span className="text-muted date">{user.payment_status} </span>
                                    </td>

                                  </tr>
                                ))
                                }
                              </tbody>
                            </table>
                          </div>



                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div >
      </body >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EscrowTransactionForecast);
