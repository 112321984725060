import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { getPhyios, displayError, displaySuccess, exportPhysio, getUnverifiedPhyios, getUserDetail, sessionList } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import axios from 'axios';
import config from '../config/config';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import Users from '../Users';

const SessionDetail = (props) => {
    const [users, setUsers] = useState([]);
    const [userData, setUserData] = useState('');
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [search, setSearch] = useState('');
    const [pageCount, setPageCount] = useState(1);
    const [total, setTotal] = useState('');

    const getList = async (search) => {
        props.toggleLoader(true);
        var getUsers_var = await sessionList(props.match.params.id);
        setUsers(getUsers_var?.data)
        setUserData(getUsers_var.data[0])
        console.log(getUsers_var.data[0], "-----abcd")
        props.toggleLoader(false);
    }
    // console.log(props.match.params.id, "=[")
    useEffect(() => {
        getList()
    }, [props])


    console.log(userData.user?.email, "===")
    return (
        <>
            <body class="g-sidenav-show  bg-gray-100">
                <div id="wrapper">
                    <Leftbar />
                    <main className="main-content border-radius-lg">
                        <Topbar />

                        <div className="header bg-primary pb-6">
                            <div className="container-fluid">
                                <div className="header-body">
                                    <div className="row align-items-center py-4">
                                        <div className="col-lg-6 col-7">
                                            <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                                                <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                                                    <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                                                    <li className="breadcrumb-item"><a href="#">Sessions</a></li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid mt--6">
                            <div className="row">
                                <div className="col">
                                    <div className="card">
                                        <div className="table-responsive">
                                            <div className="col-md-9">
                                                <div className="doc_type">{users.first_name} {users.last_name}</div>
                                                {userData != undefined &&
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-3'>
                                                                <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0, marginTop: 20 }}>User :</label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0, marginTop: 20 }}>{userData.user?.first_name} {userData.user?.last_name}</label>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-3'>
                                                                <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Service Provider :</label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{userData.doctor?.first_name} {userData.doctor?.last_name}</label>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-3'>
                                                                <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Designation :</label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{userData.doctor?.designation}</label>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-3'>
                                                                <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0, marginBottom: 20 }}>Payment Status :</label>
                                                            </div>
                                                            <div className='col-8'>
                                                                <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0, marginBottom: 20 }}>{userData?.payment_status}</label>
                                                            </div>
                                                        </div>

                                                    </>
                                                }

                                                <div>
                                                </div>
                                            </div>
                                            <table className="table align-items-center table-flush">
                                                <thead className="thead-light">
                                                    <tr>

                                                        <th>From Time</th>
                                                        <th>To Time</th>
                                                        <th>Session Status</th>
                                                        <th>Reschedule</th>
                                                        <th>Reminder</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                {users?.map((user) => (
                                                    <tr>

                                                        <td>
                                                            <span className="text-muted">{moment(user.fromTime).format('ddd, DD MMM YYYY hh:mm A')} </span>
                                                        </td>
                                                        <td>

                                                            <span className="text-muted">{moment(user.toTime).format('ddd, DD MMM YYYY hh:mm A')} </span>

                                                        </td>
                                                        <td>
                                                            <span className="text-muted">
                                                                {user.status}
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <span className="text-muted">{user?.is_rescheduled == false ? "No" : "Yes"} </span>
                                                        </td>
                                                        <td>
                                                            <span className="text-muted">{user.remind_me == false ? "No" : "Yes"} </span>
                                                        </td>

                                                        <td>
                                                            <span className="text-muted">
                                                                {user.price}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </main>
                </div>
            </body>
        </>
    )
}
const mapDispatchToProps = {
    toggleLoader
}
const mapStateToProps = (state) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SessionDetail);
