import ActionConstants from '../actionConstants/userdata';
export default function userdata(state = { userData: '' }, action) {

    switch (action.type) {
        case ActionConstants.USER_DATA: {
            return {
                ...state,
                userData: action.value
            }
        }
        default:
            return { ...state }
    }

}

