import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { exportUser, getUsers, displaySuccess, displayError, getReportedPhysio, reportAction, walletPhysioList, getPredefinedMessages, getProviderWallets } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import config from '../config/config';
import axios from 'axios';
import Select from 'react-select';

const ProviderWallet = (props) => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState('');
  const [selOptions, setSelOptions] = useState([])
  const [selected, setSelected] = useState([])
  const [selectUserId, setSelectUserId] = useState([]);


  const getWallets = async () => {
    props.toggleLoader(true);
    var res = await getProviderWallets(limit, skip, selectUserId)
    setUsers(res.data)
    props.toggleLoader(false);
  }

  useEffect(() => {
    getWallets()
  }, [skip, selectUserId])

  // const exportList = async () => {
  //   var res = await exportUser()
  //   if (res.status == 200) {
  //     window.location.href = res.data
  //   }
  // }
  const getDropdownList = async () => {
    var temp = []
    var res = await walletPhysioList()
    res.data.map((e) => {
      temp.push({
        value: e._id, label: e.full_name
      })
    })
    setSelOptions(temp)
  }

  const selectPro = (e) => {
    var temp_arr = [];
    e.map(inst => {
      temp_arr.push(inst.value);
    })
    setSelectUserId(temp_arr)
  }

  useEffect(() => {
    getDropdownList()
  }, [])

  const handlePageClick = async (e) => {
    props.toggleLoader(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected;
    setSkip(selectedPage * limit)
    // getList(search)

    props.toggleLoader(false);
  };


  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Service Provider Wallet</a></li>
                        </ol>
                      </nav>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-8">
                          <h3 className="mb-0">Service Provider Wallet Listing</h3>
                        </div>

                        <div className="col-4">
                          <Select
                            isMulti
                            name="colors"
                            options={selOptions}
                            value={selected}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              setSelected(e)
                              selectPro(e)
                            }}
                          />
                        </div>
                      </div>
                      <div className='row mt-2'>

                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Wallet Balance</th>
                            <th style={{ textAlign: 'center' }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.length > 0 ? users?.map((user) => (
                            <tr>

                              <td className="table-user">
                                <b className='name2'>{user.user?.first_name} {user?.user?.last_name} </b>
                              </td>
                              <td className="table-user">
                                <b className='name2'>{user.user?.email}</b>
                              </td>
                              <td className="table-user">
                                <b className='name2'>{user.user.doctor_interest?.name}</b>
                              </td>
                              <td className="table-user">
                                <b className='name2'>{user.balance}</b>
                              </td>


                              <td>
                                <Link to={"/wallet-transaction/" + user._id} target="_blank">
                                  <button className="btn btn-primary pull-right" style={{ width: '170px' }}
                                  >View Transactions</button>
                                </Link>
                              </td>

                            </tr>
                          ))
                            :
                            < td colSpan={7}>

                              <div style={{ textAlign: 'center' }}>
                                No Wallet
                              </div>
                            </td>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {users.length > 0 &&
                <Row>
                  {/* <Col xs={4}>
                    <div className='mb-1'>
                      <FormattedMessage id="Showing" /> {users?.length} <FormattedMessage id="accountants from page" /> {current_page} <FormattedMessage id="of" /> {pageCount}
                    </div>
                  </Col> */}
                  <Col xs={12}>
                    <div className='paginate-css'>
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        // subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </Col>
                </Row>}

              {/* {users && users.length > 0 &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {skip != 0 &&
                    <button className="btn btn-primary mr-4" onClick={(e) => {
                      setSkip(skip - limit)
                    }}> Prev </button>
                  }
                  {users.length != 0 && users?.length == limit &&
                    <button className="btn btn-primary pull-right" onClick={(e) => {
                      setSkip(skip + limit)
                    }} > Next </button>
                  }
                </div>
              } */}
            </div>

          </main>
        </div >
      </body >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProviderWallet);
