import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/mainstyle.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './ProtectedRoute';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import LoginScreen from './LoginScreen/';
import Dashboard from './Dashboard/';
import Users from './Users/';
import Physios from './Physios/';
import ViewKyc from './ViewKyc';
import LoadingSpinner from "./components/LoadingSpinner";
import ChangePassword from "./ChangePassword";
import CouponList from './Coupons/CouponList';
import AddCoupon from './Coupons/AddCoupon';
import EditCoupon from './Coupons/EditCoupon';
import SpecialtyList from './Specialties/SpecialtyList';
import AddSpecialty from './Specialties/AddSpecialty';
import EditSpecialty from './Specialties/EditSpecialty';
import TransactionsList from './Transactions/TransactionsList';
import BookingList from './Bookings/BookingList';
import InterestList from './Interests/InterestList';
import AddInterest from './Interests/AddInterest';
import EditInterest from './Interests/EditInterest';
import PredefinedMsgList from './PredefinedMessage/PredefinedMsgList';
import AddPredefinedMsg from './PredefinedMessage/AddPredefinedMsg';
import EditPredefinedMsg from './PredefinedMessage/EditPredefinedMsg';
import CancelledBookings from './CancelledBookings';
import ApprovedPhysio from './ApprovedPhysio';
import PhysioDetail from './PhysioDetail';
import UserDetail from './UserDetail';
import escrowList from './Escrow/escrowList';
import escrowTransactionList from './Escrow/escrowTransactionList';
import escrowListForecast from './Escrow/escrowListForecast';
import escrowTransactionForecast from './Escrow/escrowTransactionForecast';
import ReportedUser from './ReportedList/ReportedUser';
import ReportedPhysio from './ReportedList/ReportedPhysio';
import SessionDetail from './SessionDetail';
import ProviderWallet from './Wallet/ProviderWallet';
import WalletTransactions from './Wallet/WalletTransactions';
import Payouts from './Wallet/Payouts';
import Tickets from './Tickets/Tickets';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LoginScreen} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <ProtectedRoute exact path="/users" component={Users} />
          <ProtectedRoute exact path="/physios" component={Physios} />
          <ProtectedRoute exact path="/physio-detail/:id" component={PhysioDetail} />
          <ProtectedRoute exact path="/user-detail/:id" component={UserDetail} />
          <ProtectedRoute exact path="/booking-sessions/:id" component={SessionDetail} />
          <ProtectedRoute exact path="/approved-physios" component={ApprovedPhysio} />
          <ProtectedRoute exact path="/view-kyc/:id" component={ViewKyc} />
          <ProtectedRoute exact path="/coupons" component={CouponList} />
          <ProtectedRoute exact path="/add-coupon" component={AddCoupon} />
          <ProtectedRoute exact path="/edit-coupon/:id" component={EditCoupon} />
          <ProtectedRoute exact path="/interests" component={InterestList} />
          <ProtectedRoute exact path="/add-interest" component={AddInterest} />
          <ProtectedRoute exact path="/edit-interest/:id" component={EditInterest} />
          <ProtectedRoute exact path="/specialties" component={SpecialtyList} />
          <ProtectedRoute exact path="/predefined-messages" component={PredefinedMsgList} />
          <ProtectedRoute exact path="/add-predefined-message" component={AddPredefinedMsg} />
          <ProtectedRoute exact path="/edit-predefined-message/:id" component={EditPredefinedMsg} />
          <ProtectedRoute exact path="/add-predefined-message" component={AddPredefinedMsg} />
          <ProtectedRoute exact path="/add-specialty" component={AddSpecialty} />
          <ProtectedRoute exact path="/edit-specialty/:id" component={EditSpecialty} />
          <ProtectedRoute exact path="/transactions" component={TransactionsList} />
          <ProtectedRoute exact path="/bookings" component={BookingList} />
          <ProtectedRoute exact path="/cancelled-booking" component={CancelledBookings} />
          <ProtectedRoute exact path="/escrow" component={escrowList} />
          <ProtectedRoute exact path="/escrow-forecast" component={escrowListForecast} />
          <ProtectedRoute exact path="/escrow-transaction/:id" component={escrowTransactionList} />
          <ProtectedRoute exact path="/escrow-forecast-transaction/:id" component={escrowTransactionForecast} />
          <ProtectedRoute exact path="/reported-user" component={ReportedUser} />
          <ProtectedRoute exact path="/reported-physio" component={ReportedPhysio} />
          <ProtectedRoute exact path="/provider-wallet" component={ProviderWallet} />
          <ProtectedRoute exact path="/wallet-transaction/:id" component={WalletTransactions} />
          <ProtectedRoute exact path="/payouts" component={Payouts} />
          <ProtectedRoute exact path="/tickets" component={Tickets} />
        </Switch >
      </BrowserRouter >
      <LoadingSpinner />
    </React.Fragment >
  );
}

export default App;
