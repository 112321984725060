import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { displayError, displaySuccess, getAllTickets, getAllTransactions, ticketResolved } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import config from '../config/config';
import axios from 'axios';
import moment from 'moment';
import '../css/mainstyle.css'
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';

const Tickets = (props) => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [status, setStatus] = useState('');
  const [sort, setSort] = useState('desc');
  const [total, setTotal] = useState('');
  const [page, setPage] = useState(1);

  const getTickets = async () => {
    // setIsLoading(true)
    props.toggleLoader(true);
    var res = await getAllTickets(page, 10, sort, status)
    setPageCount(res.data.metadata?.totalPage)
    setUsers(res.data.data);
    props.toggleLoader(false);
  }


  const handlePageClick = async (e) => {
    props.toggleLoader(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected;
    setPage(selectedPage + 1)
    // getList(search)

    props.toggleLoader(false);
  };


  useEffect(() => {
    getTickets()
  }, [page, sort, status])

  const markResolve = async (id) => {
    var res = await ticketResolved(id)
    console.log(res)
    displaySuccess('Ticket Resolved!');

    getTickets()
  }

  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Tickets</a></li>
                        </ol>
                      </nav>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6" >
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-6">
                          <h3 className="mb-0">Tickets Listing </h3>
                        </div>
                        {/* <div className='col-6 display-end' >
                          <input type="text" className="form-control"
                            style={{ width: '35%', marginRight: 10 }}
                            placeholder="Search"
                            value={search}
                            onChange={(e) => {
                              setSkip(0)
                              setPageCount(1)
                              setSearch(e.target.value)
                              // const value = e.target.value;
                              // const regex = /^[0-9]+$/;
                              // if (value.match(regex) || value === "") {
                              //   setQuantity(value)
                              // }
                            }}
                          />
                        </div> */}
                      </div>
                      <div className='row'>
                        <div className="form-group" style={{ width: '15%', marginRight: '10px' }}>
                          <label className="form-control-label" >Status</label>
                          <select className="form-control"
                            onChange={e => setStatus(e.target.value)}
                            value={status}
                          >
                            <option value="">All</option>
                            <option value="open">Open</option>
                            <option value="resolved">Closed</option>
                          </select>
                        </div>
                        <div className="form-group" style={{ width: '15%' }}>
                          <label className="form-control-label" >Sort</label>
                          <select className="form-control"
                            onChange={e => setSort(e.target.value)}
                            value={sort}
                          >
                            <option value="desc">Date descending</option>
                            <option value="asc">Date ascending</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>User Name</th>
                            <th>Issue</th>
                            <th>Note</th>
                            <th>Status</th>
                            <th>Reported On</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.length > 0 ? users?.map((user) => (
                            <tr>
                              <td className="table-user">
                                <b>{user?.user_info?.first_name} {user?.user_info?.last_name}</b>
                              </td>
                              <td>
                                <span className="text-muted">{user?.suppot_option?.name}</span>
                              </td>

                              <td style={{ width: '20%' }}>
                                <span className="text-muted">{user?.description}</span>
                              </td>
                              <td>
                                <span className="text-muted">{user?.status == 'open' ? 'Open' : 'Closed'}</span>
                              </td>

                              <td>
                                <span className="text-muted">{moment(user?.created_at).format('DD/MM/YYYY hh:mm A')}</span>
                              </td>

                              <td>
                                {user?.status == 'open' ?
                                  <button className="btn btn-primary pull-right" style={{ width: '160px' }}
                                    onClick={() => { markResolve(user?._id) }}>Mark as Resolved</button>
                                  : <span className="text-muted">Resolved</span>}
                              </td>

                            </tr>
                          ))
                            :
                            <td colSpan={6}>

                              <div style={{ textAlign: 'center' }}>
                                No Tickets
                              </div>
                            </td>

                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {users.length > 0 &&
                <Row>
                  {/* <Col xs={4}>
                    <div className='mb-1'>
                      <FormattedMessage id="Showing" /> {users?.length} <FormattedMessage id="accountants from page" /> {current_page} <FormattedMessage id="of" /> {pageCount}
                    </div>
                  </Col> */}
                  <Col xs={12}>
                    <div className='paginate-css'>
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        // subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </Col>
                </Row>}
            </div>
          </main>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
