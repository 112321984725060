import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { displayError, displaySuccess, getInterests } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import config from '../config/config';
import axios from 'axios';

const InterestList = (props) => {
  const [interests, setInterests] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [is_delete, setIsDelete] = useState('');
  const [temp_var_id, setTempVarId] = useState('');

  const getList = async () => {
    props.toggleLoader(true);
    var getInterests_var = await getInterests(limit, skip);
    setInterests(getInterests_var.data);
    props.toggleLoader(false);
  }

  useEffect(() => {
    getList()
  }, [skip])


  const deleteInterest = async () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.delete(path + "/delete-interest/" + temp_var_id, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        displaySuccess('Deleted Successfully');
        getList()
        props.toggleLoader(false);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      });
  }

  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Interests</a></li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-9">
                          <h3 className="mb-0">Interests Listing </h3>
                        </div>
                        <div className="col-3">
                          <h3 className="mb-0 display-end" >
                            <Link to={"/add-interest"} target="_blank">
                              <button className="btn btn-primary pull-right" style={{ marginRight: '5px' }}>Add Interest</button>
                            </Link></h3>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>Name</th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {interests?.map((interest) => (
                            <tr>
                              <td className="table-user">
                                <b>{interest.name} </b>
                              </td>
                              <td>
                                <Link to={"/edit-interest/" + interest._id} target="_blank">
                                  <button className="btn btn-primary pull-right" style={{ marginRight: '5px' }}>Edit</button>
                                </Link>
                                <button className="btn btn-danger pull-right "
                                  onClick={e => {
                                    setTempVarId(interest._id)
                                    setIsDelete(true)
                                  }}>Delete</button>
                              </td>

                            </tr>
                          ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {interests && interests.length > 0 &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {skip != 0 &&
                    <button className="btn btn-primary mr-4" onClick={(e) => {
                      setSkip(skip - limit)
                    }}> Prev </button>
                  }
                  {interests.length != 0 && interests?.length == limit &&
                    <button className="btn btn-primary pull-right" onClick={(e) => {
                      setSkip(skip + limit)
                    }} > Next </button>
                  }
                </div>
              }
            </div>
            {is_delete === true ? (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                title='Are you sure?'
                onConfirm={(e) => {
                  deleteInterest()
                  setIsDelete(false)
                  // window.location.reload(false)
                }}
                onCancel={(e) => {
                  setIsDelete(false)
                  setTempVarId('')
                }}
                focusCancelBtn
              >
                Do you want to delete this interest?
              </SweetAlert>
            ) : ([]
            )}

          </main>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InterestList);